import { atom, selector } from "recoil";
import { Pt40Component } from "../data/data";

export interface SelectedPT40Components {
  valveFinish: Pt40Component | null;

  pipeMaterial: Pt40Component | null;

  valveBody: Pt40Component | null;

  lockShield: Pt40Component | null;

  sensorAndHeads: Pt40Component | null;
  accessories: Pt40Component | null;
}

const defaultState: SelectedPT40Components = {
  valveFinish: null,
  pipeMaterial: null,
  valveBody: null,
  lockShield: null,
  sensorAndHeads: null,
  accessories: null,
};

export const pt40ResidentialAtom = atom<SelectedPT40Components>({
  key: "pt40Residential",
  default:
    JSON.parse(localStorage.getItem("pt40Residential") || "null") ??
    defaultState,
});

export const pt40ResidentialTabIndexAtom = atom<number>({
  key: "pt40ResidentialTabIndex",
  default:
    +JSON.parse(localStorage.getItem("pt40ResidentialTabIndex") || "0") ?? 0,
});

export const pt40ResidentialTabIndexSelector = selector<number>({
  key: "pt40ResidentialTabIndexSelector",
  get: ({ get }) => {
    const pt40ResidentialTabIndex = get(pt40ResidentialTabIndexAtom);
    return pt40ResidentialTabIndex;
  },
  set: ({ set }, newValue) => {
    localStorage.setItem("pt40ResidentialTabIndex", JSON.stringify(newValue));
    set(pt40ResidentialTabIndexAtom, newValue);
  },
});

export const pt40ResidentialSelector = selector<SelectedPT40Components>({
  key: "pt40ResidentialSelector",
  get: ({ get }) => {
    const pt40Residential = get(pt40ResidentialAtom);
    return pt40Residential;
  },
  set: ({ set }, newValue) => {
    localStorage.setItem("pt40Residential", JSON.stringify(newValue));
    set(pt40ResidentialAtom, newValue);
  },
});
