import { atom, selector } from "recoil";
import { Pt40Component } from "../data/data";

export interface SelectedRT40Components {
  valveFinish: Pt40Component | null;

  pipeMaterial: Pt40Component | null;

  valveBody: Pt40Component | null;

  lockShield: Pt40Component | null;

  heads: Pt40Component | null;
}

const defaultState: SelectedRT40Components = {
  valveFinish: null,
  pipeMaterial: null,
  valveBody: null,
  lockShield: null,
  heads: null,
};

export const rt40TowelRailSetAtom = atom<SelectedRT40Components>({
  key: "rt40TowelRailSet",
  default:
    JSON.parse(localStorage.getItem("rt40TowelRailSet") || "null") ??
    defaultState,
});

export const rt40TowelRailSetAtomTabIndexAtom = atom<number>({
  key: "rt40TowelRailSetTabIndex",
  default:
    +JSON.parse(localStorage.getItem("rt40TowelRailSetTabIndex") || "0") ?? 0,
});

export const rt40TowelRailSetTabIndexSelector = selector<number>({
  key: "rt40TowelRailSetTabIndexSelector",
  get: ({ get }) => {
    const pt40CommercialTabIndex = get(rt40TowelRailSetAtomTabIndexAtom);
    return pt40CommercialTabIndex;
  },
  set: ({ set }, newValue) => {
    localStorage.setItem("rt40TowelRailSetTabIndex", JSON.stringify(newValue));
    set(rt40TowelRailSetAtomTabIndexAtom, newValue);
  },
});

export const rt40TowelRailSetSelector = selector<SelectedRT40Components>({
  key: "rt40TowelRailSetSelector",
  get: ({ get }) => {
    const pt40Commercial = get(rt40TowelRailSetAtom);
    return pt40Commercial;
  },
  set: ({ set }, newValue) => {
    localStorage.setItem("rt40TowelRailSet", JSON.stringify(newValue));
    set(rt40TowelRailSetAtom, newValue);
  },
});
