import { useRecoilValue } from "recoil";
import {
  SelectedPT40Components,
  pt40ResidentialSelector,
} from "../recoil/pt40-residential-recoil-state";
import { useMemo } from "react";
import _ from "lodash";
import { SelectedRT40Components } from "../recoil/rt40-towel-rail-set-state";

export const DEFAULT_PT40_RESIDENTIAL_PRODUCT_CODE = "Pt40/";

export const useGetPT40ResidentialProductCode = () => {
  const pt40ComponentState = useRecoilValue(pt40ResidentialSelector);

  const productCode = useMemo(() => {
    let productCode = DEFAULT_PT40_RESIDENTIAL_PRODUCT_CODE;
    Object.values(pt40ComponentState)
      .filter((entry) => !_.isEmpty(entry))
      .forEach((value) => {
        if (value) {
          productCode += value.code;
        }
      });
    return productCode;
  }, [pt40ComponentState]);

  const calculateCurrentProductCode = (
    currentStep: keyof SelectedPT40Components
  ) => {
    //Calculate the product code of all the previous steps up to but not including the current step
    let productCode = DEFAULT_PT40_RESIDENTIAL_PRODUCT_CODE;

    const entries = Object.entries(pt40ComponentState);
    for (const [key, value] of entries) {
      if (key === currentStep) {
        return productCode;
      }
      if (value) {
        productCode += value.code;
      }
    }
  };

  const createMailToWithProductCode = () => {
    const subject = "PT40 Product Code";
    const body = `I used the configurator on your website and would like to place an order for the following product(s): ${productCode}`;
    const mailto = `mailto:dave.elliott@sav-systems.com?subject=${subject}&body=${body}`;
    return mailto;
  };

  return {
    productCode,
    createMailToWithProductCode,
    calculateCurrentProductCode,
  };
};
