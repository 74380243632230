import { useRecoilValue } from "recoil";
import { useMemo } from "react";
import _ from "lodash";
import {
  SelectedRT40Components,
  rt40TowelRailSetSelector,
} from "../recoil/rt40-towel-rail-set-state";

export const DEFAULT_RT40_TOWEL_RAIL_SET_PRODUCT_CODE = "Rt40/";

export const useGetRT40TowlRailSetProductCode = () => {
  const rt40ComponentState = useRecoilValue(rt40TowelRailSetSelector);

  const productCode = useMemo(() => {
    let productCode = DEFAULT_RT40_TOWEL_RAIL_SET_PRODUCT_CODE;
    Object.values(rt40ComponentState)
      .filter((entry) => !_.isEmpty(entry))
      .forEach((value) => {
        if (value) {
          productCode += value.code;
        }
      });
    return productCode;
  }, [rt40ComponentState]);

  const calculateCurrentProductCode = (
    currentStep: keyof SelectedRT40Components
  ) => {
    //Calculate the product code of all the previous steps up to but not including the current step
    let productCode = DEFAULT_RT40_TOWEL_RAIL_SET_PRODUCT_CODE;

    const entries = Object.entries(rt40ComponentState);
    for (const [key, value] of entries) {
      if (key === currentStep) {
        return productCode;
      }
      if (value) {
        productCode += value.code;
      }
    }
  };

  const createMailToWithProductCode = () => {
    const subject = "RT40 Product Code";
    const body = `I used the configurator on your website and would like to place an order for the following product(s): ${productCode}`;
    const mailto = `mailto:dave.elliott@sav-systems.com?subject=${subject}&body=${body}`;
    return mailto;
  };

  return {
    productCode,
    createMailToWithProductCode,
    calculateCurrentProductCode,
  };
};
