import { atom, selector } from "recoil";
import { Pt40Component } from "../data/data";

export interface SelectedPT40Components {
  valveFinish: Pt40Component | null;

  pipeMaterial: Pt40Component | null;

  valveBody: Pt40Component | null;

  lockShield: Pt40Component | null;

  sensorAndHeads: Pt40Component | null;
  accessories: Pt40Component | null;
}

const defaultState: SelectedPT40Components = {
  valveFinish: null,
  pipeMaterial: null,
  valveBody: null,
  lockShield: null,
  sensorAndHeads: null,
  accessories: null,
};

export const pt40CommercialAtom = atom<SelectedPT40Components>({
  key: "pt40Commercial",
  default:
    JSON.parse(localStorage.getItem("pt40Commercial") || "null") ??
    defaultState,
});

export const pt40CommercialTabIndexAtom = atom<number>({
  key: "pt40CommercialTabIndex",
  default:
    +JSON.parse(localStorage.getItem("pt40CommercialTabIndex") || "0") ?? 0,
});

export const pt40CommercialTabIndexSelector = selector<number>({
  key: "pt40CommercialTabIndexSelector",
  get: ({ get }) => {
    const pt40CommercialTabIndex = get(pt40CommercialTabIndexAtom);
    return pt40CommercialTabIndex;
  },
  set: ({ set }, newValue) => {
    localStorage.setItem("pt40CommercialTabIndex", JSON.stringify(newValue));
    set(pt40CommercialTabIndexAtom, newValue);
  },
});

export const pt40CommercialSelector = selector<SelectedPT40Components>({
  key: "pt40CommercialSelector",
  get: ({ get }) => {
    const pt40Commercial = get(pt40CommercialAtom);
    return pt40Commercial;
  },
  set: ({ set }, newValue) => {
    localStorage.setItem("pt40Commercial", JSON.stringify(newValue));
    set(pt40CommercialAtom, newValue);
  },
});
