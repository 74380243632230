import { RT40TowelRailSetData } from "../../data/data";
import Rt40Selector from "./RT40Selector";
import {
  rt40TowelRailSetSelector,
  rt40TowelRailSetTabIndexSelector,
} from "../../recoil/rt40-towel-rail-set-state";
import { useGetRT40TowlRailSetProductCode } from "../../hooks/useRt40TowelRaiSetsProductCode";

export default function RT40SelectorContainer() {
  return (
    <div>
      <div id="pt40-header-bg">
        <h2 id="pt40" className="text-white">
          Rt40 Configurator
        </h2>
      </div>
      <div className="container-main-content">
        <div
          className="container-innner flex justify-between
      "
        >
          <div className="title-and-desc">
          <h2
            style={{
              font: "ItalianPlateNo2Condensed",
              textAlign: "center",
              color: "#0032A5",
              fontSize: "2.5rem",
              fontWeight: 800,
            }}
          >
            Rt40 Configurator
          </h2>
          <p>Build your product in the configurator below. Follow the steps, choose your materials, and you’ll get a product code for your enquiry at the final ‘summary’ step.</p>
          </div>
        </div>
        <Rt40Selector
          rt40ComponentData={RT40TowelRailSetData}
          recoilStatePt40Component={rt40TowelRailSetSelector}
          recoilTabSelector={rt40TowelRailSetTabIndexSelector}
          rt40ProductCodeHook={useGetRT40TowlRailSetProductCode}
        />
      </div>
    </div>
  );
}
