import { Tab, Tabs } from "@mui/material";
import React, { useMemo } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Pt40Selector from "./PT40Selector";
import {
  pt40ResidentialSelector,
  pt40ResidentialTabIndexSelector,
} from "../../recoil/pt40-residential-recoil-state";
import { PT40CommercialData, PT40ResidentialData } from "../../data/data";
import { useGetPT40ResidentialProductCode } from "../../hooks/usePt40ResidentialProductCode";
import {
  pt40CommercialSelector,
  pt40CommercialTabIndexSelector,
} from "../../recoil/pt40-commercial-recoil-state";
import { useGetPT40CommercialProductCode } from "../../hooks/usePt40CommercialProductCode";
import { Height } from "@mui/icons-material";

export default function PT40SelectorContainer() {
  const [selectedConfigurator, setSelectedConfigurator] =
    React.useState<number>(0);

  const selectedTabLabel = useMemo(() => {
    if (selectedConfigurator === 0) {
      return "Residential";
    } else if (selectedConfigurator === 1) {
      return "Commercial";
    }
  }, [selectedConfigurator]);
  return (
    <div>
      <div id="pt40-header-bg">
        <h2 id="pt40" className="text-white">
          Pt40 Configurator
        </h2>
      </div>
      <div className="container-main-content">
        <div
          className="container-innner flex justify-between 
      "
        >
          <div className="title-and-desc">
          <h2
            style={{
              font: "ItalianPlateNo2Condensed",
              textAlign: "center",
              color: "#0032A5",
              fontSize: "2.5rem",
              fontWeight: 800,
            }}
          >
            Pt40 {selectedTabLabel} Configurator
          </h2>
          <p>Build your product in the configurator below. Follow the steps, choose your materials, and you’ll get a product code for your enquiry at the final ‘summary’ step.</p>
          </div>
          <div className="flex flex-row type-switch">
            <Tabs
              value={selectedConfigurator}
              onChange={(event, value) => setSelectedConfigurator(value)}
              aria-label="icon label tabs example"
            >
              <Tab
                icon={<AccountBalanceIcon />}
                iconPosition="start"
                label="Residential"
              />
              <Tab
                icon={<AccountBalanceWalletIcon />}
                iconPosition="start"
                label="Commercial"
              />
            </Tabs>
          </div>
        </div>
        {selectedConfigurator === 0 ? (
          <Pt40Selector
            recoilTabSelector={pt40ResidentialTabIndexSelector}
            recoilStatePt40Component={pt40ResidentialSelector}
            pt40ComponentData={PT40ResidentialData}
            pt40ProductCodeHook={useGetPT40ResidentialProductCode}
          />
        ) : (
          <Pt40Selector
            recoilTabSelector={pt40CommercialTabIndexSelector}
            recoilStatePt40Component={pt40CommercialSelector}
            pt40ComponentData={PT40CommercialData}
            pt40ProductCodeHook={useGetPT40CommercialProductCode}
          />
        )}
      </div>
    </div>
  );
}
