import { Navigate, Route, Routes } from "react-router-dom";
import React, { useRef, useEffect } from 'react';
import "./App.css";
import PT40SelectorContainer from "./selectors/PT40/PT40SelectorContainer";
import RT40SelectorContainer from "./selectors/RT40/RT40SelectorContainer";


  const App: React.FC = () => {
    const rootDivRef = useRef<HTMLDivElement | null>(null);
    const resizeObserverRef = useRef<ResizeObserver | null>(null);
  
    useEffect(() => {
      const debounce = (func: (...args: any[]) => void, delay: number) => {
        let inDebounce: NodeJS.Timeout;
        return function (...args: any[]) {
          clearTimeout(inDebounce);
          inDebounce = setTimeout(() => func(...args), delay);
        };
      };
  
      const updateHtmlHeight = () => {
        const rootDiv = rootDivRef.current;
        if (rootDiv) {
          const newHeight = `${rootDiv.offsetHeight}px`;
          const currentHtmlHeight = document.documentElement.style.height;
  
          if (newHeight !== currentHtmlHeight) {
            document.documentElement.style.height = newHeight;
          }
        }
      };
  
      const debouncedUpdateHtmlHeight = debounce(updateHtmlHeight, 100);
  
      const rootDiv = document.getElementById('root') as HTMLDivElement | null;
      rootDivRef.current = rootDiv;
  
      if (rootDiv) {
        const resizeObserver = new ResizeObserver(debouncedUpdateHtmlHeight);
        resizeObserver.observe(rootDiv);
        resizeObserverRef.current = resizeObserver;
  
        // Run once to set the initial height
        debouncedUpdateHtmlHeight();
      }
  
      // Cleanup function to disconnect ResizeObserver
      return () => {
        if (resizeObserverRef.current && rootDivRef.current) {
          resizeObserverRef.current.unobserve(rootDivRef.current);
          resizeObserverRef.current.disconnect();
        }
      };
    }, []);
  
    const sendHeightToParent = () => {
      const heightStyle = document.documentElement.style.height;
      const height = parseInt(heightStyle, 10);
      window.parent.postMessage({ type: 'setDimensions', height }, '*'); // '*' can be replaced with the specific parent domain for added security
    };
  
    useEffect(() => {
      // Send height when the component mounts
      setTimeout(() => {
        sendHeightToParent();
      }, 1000);
  
      // Send height whenever the window is resized
      window.addEventListener('resize', sendHeightToParent);
  
      // Set up a MutationObserver to send height when the DOM changes
      const observer = new MutationObserver(sendHeightToParent);
      observer.observe(document.documentElement, { attributes: true, childList: true, subtree: true });
  
      // Clean up event listener and observer when the component unmounts
      return () => {
        window.removeEventListener('resize', sendHeightToParent);
        observer.disconnect();
      };
    }, []); 
  
  
  return (
    <>
      <Routes>
        <Route path="/pt40-selector" element={<PT40SelectorContainer />} />
        <Route
          path="/rt-40-tower-rail-sets"
          element={<RT40SelectorContainer />}
        />
        <Route path="*" element={<Navigate to="/pt40-selector" />} />
      </Routes>
    </>
  );
}

export default App;
