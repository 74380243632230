import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Button,
  Radio,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useState } from 'react';
import { Pt40Component } from "../data/data";
import { camelCaseToTitleCase } from "../util";
import tooltipIcon from '../static/icons/tooltip-icon.svg';
import bestPracticeIcon from '../static/icons/star.png';
import selectedTabState from "../selectors/PT40/PT40Selector";
import maxStep from "../selectors/PT40/PT40Selector";
import { BorderRight } from "@mui/icons-material";



type Props = {
  index: number;
  component: Pt40Component;
  isSelected: boolean;
  indexKey?: string;
  header: string;
  tooltip?: string;
  bestPractice?: boolean;
  onClick: () => void;
};

export default function ComponentCard(props: Props) {
  const {
    isSelected,
    indexKey,
    header,
    component: { name, imageSource },
    onClick,
    tooltip,
    bestPractice,
    index,
  } = props;

const [showTooltipContent, setShowTooltipContent] = useState(false);
const handleTooltipClick = () => {
  setShowTooltipContent(!showTooltipContent);
};


  // console.log(imageSource);
  return (
    <Card
      sx={{
        minWidth: 250,
        maxWidth: 500,
        margin: "0.5rem",
        backgroundColor: isSelected ? "#FFDC00" : "",
        position: "relative",
      }}
    >
      <div style={{backgroundColor: isSelected ? "#FFDC00" : "#fff", display: showTooltipContent ? 'flex' : 'none' }} className="flex flex-row tooltip-content"><h2 className="tooltip-name">{name}</h2><span>{tooltip}</span></div>
      <CardActionArea onClick={onClick} className="card-inner-custom">
      {tooltip && (
        <div className="flex flex-row tooltip-custom">
          <img className="tooltip-icon" style={{backgroundColor: showTooltipContent ? "#0032a51f" : "transparent"}}
            src={tooltipIcon}
            alt="info"
            onClick={(event) => {
              event.stopPropagation(); // Prevents the CardActionArea's onClick from being called
              handleTooltipClick();
            }}
          />
        </div>
        )}

        <CardMedia className="main-img-custom"
          sx={{
            marginTop: "1rem",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "50%",
            maxWidth: 200,
            maxHeight: 200,
            ":hover": {
              border: "1px solid #0032a5",
              borderRadius: "10px",
              objectFit: "contain",
            },
          }}
          component="img"
          image={imageSource || "https://via.placeholder.com/200"}
          alt={name}
        />
        <CardContent className="card-content-custom">
          {bestPractice && (
          <div className="best-practices">
            <img src={bestPracticeIcon}></img>
            <p>Best Practice</p>
          </div>
          )}
          {!bestPractice && (
          <div style={{ height: "15px" }}>
          </div>
          )}
          <p style={{ color: "#0032A5", fontSize: "0.8rem", fontWeight: "300", }}>
            {indexKey && ` ${index + 1}. ${camelCaseToTitleCase(indexKey)} `}
          </p>
          <Typography style={{fontSize: "20px", color: "#3D3D3E", fontFamily: "ItalianPlateDemiBold" }} gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <div className="flex flex-row justify-between">
            <Typography variant="body2" color="text.secondary" style={{ fontSize: "14px", fontWeight: "300px",  color: isSelected ? "#212121" : "#828282" }}>
              {header}
            </Typography>
            {isSelected && <Radio checked={true} style={{ color: "black", width: "16px", height: "16px", }} />}
            {!isSelected && !indexKey && <Radio checked={false} style={{ color: "transparent", backgroundColor:"#E0E0E0", width: "10px", height: "10px", }} />}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
