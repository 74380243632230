export interface Pt40Component {
  readonly name: string;
  readonly code: string;
  readonly imageSource: string;
  readonly tooltip?: string;
  readonly bestPractice?: boolean
}

export interface Pt40ComponentWithAllowedProductCodes extends Pt40Component {
  /**
   * This are the compoinents that should be filtered out based on the previous component picked
   */
  readonly allowedProductCodes?: string[];
}
export enum Steps {
  valveFinish = 1,
  pipeMaterial = 2,
  valveBody = 3,
  lockShield = 4,
  sensorAndHeads = 5,
}

export interface PT40ComponentGroup {
  valveFinish: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
  };
  pipeMaterial: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
  };
  valveBody: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
  };
  lockShield: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
  };
  sensorAndHeads: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
  };
  accessories: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
  };
}

export interface RT40ComponentGroup {
  valveFinish: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
    tooltip?: string;
    bestPractice?: boolean;
  };
  pipeMaterial: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
    tooltip?: string;
    bestPractice?: boolean;
  };
  valveBody: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
    tooltip?: string;
    bestPractice?: boolean;
  };
  lockShield: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
    tooltip?: string;
    bestPractice?: boolean;
  };
  heads: {
    title: string;
    step: number;
    components: Pt40ComponentWithAllowedProductCodes[];
    productCodeIndicies: [number, number];
    tooltip?: string;
    bestPractice?: boolean;
  };
}

export const RT40TowelRailSetData: RT40ComponentGroup = {
  valveFinish: {
    title: "Valve Finish",
    step: 1,
    productCodeIndicies: [0, 0],
    components: [
      {
        name: "Matt Nickel",
        code: "N",
        imageSource: require("../static/components/matt-nickel-valve-finish.png"),
        tooltip: "A commercial nickel finish.",
      },
    ],
  },
  pipeMaterial: {
    title: "Pipe Material",
    step: 2,
    productCodeIndicies: [0, 1],
    components: [
      {
        name: "15 mm Copper",
        code: "C",
        imageSource: require("../static/components/15-mm-copper-pipe-material.png"),
        tooltip: "Supplied with compression fittings for 15mm copper pipe.",
      },
      {
        name: "10 mm PEX",
        code: "P",
        imageSource: require("../static/components/10-mm-pex-pipe-material.png"),
        tooltip: "Supplied with JG speedfit (push-fit) elbows for 10mm PEX pipe."
      },
    ],
  },
  valveBody: {
    title: "Valve Body",
    step: 3,
    productCodeIndicies: [1, 4],
    components: [
      {
        name: "Vertical Male",
        code: "-Vm",
        imageSource: require("../static/components/vertical-male-valve-body.png"),
        tooltip: "Should be installed on the return connection / outlet of towel rail. Will place the thermostatic sensor head pointing downwards. If installed on a T-piece for dual fuel heating elements, the thermostatic sensor head will be neatly tucked below the towel rail."
      },
    ],
  },
  lockShield: {
    title: "Lock Shield",
    step: 4,
    productCodeIndicies: [4, 6],
    components: [
      {
        name: "Angle Male",
        code: "Vm",
        imageSource: require("../static/components/angle-male-lock-shield.png"),
        tooltip: "For isolation only, to be left fully open during operation. To be installed on the flow connection / inlet of towel rail."
      },
      {
        name: "Straight Male",
        code: "Sm",
        imageSource: require("../static/components/straight-male-lock-shield.png"),
        tooltip: "For isolation only, to be left fully open during operation. To be installed on the flow connection / inlet of towel rail. Can be used with dual fuel towel rails where the pipe connections come from the floor."
      },
    ],
  },
  heads: {
    title: "Heads",
    step: 5,
    productCodeIndicies: [6, 8],
    components: [
      {
        name: "Chrome, Return Temperature Limiting Head",
        code: "-C",
        imageSource: require("../static/components/chrome-return-temperature-limiting-head.png"),
        tooltip: "Return temperature limited to a maximum of 40˚C.",
      },
      {
        name: "White, Return Temperature Limiting Head",
        code: "-W",
        imageSource: require("../static/components/white-return-temperature-limiting-head.png"),
        tooltip: "Return temperature limited to a maximum of 40˚C.",
      },
    ],
  },
};

export const PT40CommercialData: PT40ComponentGroup = {
  valveFinish: {
    title: "Valve Finish",
    step: 1,
    productCodeIndicies: [0, 0],
    components: [
      {
        name: "Nickel",
        code: "N",
        imageSource: require("../static/components/satin-nickel-valve-finish.png"),
        tooltip: "A commercial nickle finish.",
      },
    ],
  },
  pipeMaterial: {
    title: "Pipe Material",
    step: 2,
    productCodeIndicies: [0, 1],
    components: [
      {
        name: "15 mm Steel",
        code: "S",
        imageSource: require("../static/components/steel-pipe-material.png"),
        tooltip: "Supplied with 1/2'' female BSP threaded valve connections for steel barrel pipe."
      },
    ],
  },
  valveBody: {
    title: "Valve Body",
    step: 3,
    productCodeIndicies: [1, 4],
    components: [
      {
        name: "Axial Female",
        code: "-Af",
        imageSource: require("../static/components/axial-female-valve-body.png"),
        tooltip: "Designed for all radiator connection types, TBOE, TBSE and BBO. Ensures optimal positioning of thermostatic sensor head for improved indoor comfort.",
        bestPractice: true,
      },
      {
        name: "Angle Female",
        code: "-Vf",
        imageSource: require("../static/components/angle-female-valve-body.png"),
        tooltip: "Should be used where the installation of Axial valve is not possible due to spatial constraints. To ensure optimal positioning of thermostatic sensor head, the valve should ideally be mounted horizontally (head pointing into room)."
      },
      {
        name: "Straight Female",
        code: "-Sf",
        imageSource: require("../static/components/straight-female-valve-body.png"),
        tooltip: "To ensure optimal positioning of thermostatic sensor head, the valve should ideally be mounted horizontally (head pointing into room)."
      },
    ],
  },
  lockShield: {
    productCodeIndicies: [4, 6],
    title: "Lock Shield",
    step: 4,
    components: [
      {
        name: "Angle Female",
        code: "Vf",
        imageSource: require("../static/components/angle-female-lock-shield.png"),
        tooltip: "For isolation only, to be left fully open during operation.",
      },
      {
        name: "Straight Female",
        code: "Sf",
        imageSource: require("../static/components/straight-female-lock-shield.png"),
        tooltip: "For isolation only, to be left fully open during operation.",
      },
    ],
  },
  sensorAndHeads: {
    productCodeIndicies: [6, 8],
    title: "Sensors & Heads",
    step: 5,
    components: [
      {
        name: "Snap-on-Gas",
        code: "-S",
        imageSource: require("../static/components/snap-on-gas-sensor-and-heads.png"),
        tooltip: "Fastest thermostatic sensor on the market. Approximately 5% energy savings when compared to liquid sensors. Built in temperature limiting and tamper proofing. Hygienic and easy clean for healthcare premises.",
        bestPractice: true,
      },
      {
        name: "Manuel Non-thermostatic",
        code: "-M",
        imageSource: require("../static/components/manuel-non-thermostatic-sensor-and-heads.png"),
        tooltip: "Required in the room with the programmable room thermostat.",
      },
      {
        name: "Remote Sensor",
        code: "-R",
        imageSource: require("../static/components/remote-senor-sensor-and-heads.png"),
        tooltip: "Remote sensor (2m) from radiator for improved comfort. Required for LST radiators and partially obstructed radiators.",
      },
      {
        name: "Remote Adjuster",
        code: "-A",
        imageSource: require("../static/components/remote-adjuster-senor-and-heads.png"),
        tooltip: "Remote adjuster (5m) from radiator for improved acessibility. Large, ergonomic and easy adjustable dial for assisted living and care homes. Can be used with LST radiators.",
      },
      {
        name: "Liquid",
        code: "-L",
        imageSource: require("../static/components/liquid-sensor-and-heads.png"),
        tooltip: "Cost effective thermostatic sensor.",
      },
      {
        name: "Liquid Chrome",
        code: "-C",
        imageSource: require("../static/components/liquid-chrome-sensor-and-heads.png"),
        tooltip: "Chrome asthetics for designer homes Danfoss living design®.",
      },
      {
        name: "Low Temp 22°C",
        code: "-F",
        imageSource: require("../static/components/low-temp-22-sensor-and-heads.png"),
        tooltip: "For precises temperature limiting and control for commercial applications. Aproximately 5% energy saving for each 1°C lower room temperature."
      },
      {
        name: "Eco 2 E-TRV",
        code: "-E",
        imageSource: require("../static/components/eco-2-e-trv-sensor-and-heads.png"),
        tooltip: "Danfoss Eco™ stand-alone smart radiator thermostat that gives easy in-home control of each individual radiator. The thermostat works with Bluetooth® control and schedule individual radiators from a smartphone device."
      },
    ],
  },
  accessories: {
    productCodeIndicies: [8, 11],
    title: "Accessories",
    step: 6,
    components: [
      {
        name: "Drain Cock",
        code: "C",
        imageSource: require("../static/components/drain-cock-accessories.png"),
        tooltip: "For radiators connection types: TBOE and TBSE. Neater looking mounting."
      },
      {
        name: "No Accessories",
        code: "",
        imageSource: require("../static/components/no-image.png"),
        tooltip: "No accessories",
      },
    ],
  },
};

export const PT40ResidentialData: PT40ComponentGroup = {
  valveFinish: {
    productCodeIndicies: [0, 0],
    title: "Valve Finish",
    step: 1,
    components: [
      {
        name: "Nickel",
        code: "N",
        imageSource: require("../static/components/nickel-valve-finish.png"),
        tooltip: "A commercial nickle finish.",
      },
      {
        name: "Satin Nickel",
        code: "S",
        imageSource: require("../static/components/satin-nickel-valve-finish.png"),
        tooltip: "A polished finish created for the UK residential market.",
      },
    ],
  },
  pipeMaterial: {
    productCodeIndicies: [0, 1],
    title: "Pipe Material",
    step: 2,
    components: [
      {
        name: "15 mm Copper",
        code: "C",
        imageSource: require("../static/components/15-mm-copper-pipe-material.png"),
        tooltip: "Supplied with compression fittings for 15mm copper pipe."
      },
      {
        name: "10 mm PEX",
        code: "P",
        imageSource: require("../static/components/10-mm-pex-pipe-material.png"),
        tooltip: "Supplied with JG speedfit (push-fit) elbows for 10mm PEX pipe."
      },
    ],
  },
  valveBody: {
    productCodeIndicies: [1, 4],
    title: "Valve Body",
    step: 3,
    components: [
      {
        name: "Axial Male",
        code: "-Am",
        imageSource: require("../static/components/axial-male-valve-body.png"),
        allowedProductCodes: ["SC", "SP"],
        tooltip: "Designed for all radiator connection types, TBOE, TBSE and BBO. Ensures optimal positioning of thermostatic sensor head for improved indoor comfort.",
        bestPractice: true,
      },
      {
        name: "Angle Male",
        code: "-Vm",
        imageSource: require("../static/components/angle-male-valve-body.png"),
        allowedProductCodes: ["NC", "NP"],
        tooltip: "Should be used where the installation of Axial valve is not possible due to spatial constraints. To ensure optimal positioning of thermostatic sensor head, the valve should ideally be mounted horizontally with the head pointing into room.",
      },
      {
        name: "Straight Female",
        code: "-Sf",
        imageSource: require("../static/components/straight-female-valve-body.png"),
        allowedProductCodes: ["NC"],
        tooltip: "To ensure optimal positioning of thermostatic sensor head, the valve should ideally be mounted horizontally (head pointing into room)."
      },
    ],
  },
  lockShield: {
    productCodeIndicies: [4, 6],
    title: "Lock Shield",
    step: 4,
    components: [
      {
        name: "Angle Male",
        code: "Vm",
        imageSource: require("../static/components/angle-male-lock-shield.png"),
        tooltip: "For isolation only, to be left fully open during operation."
      },
      {
        name: "Straight Male",
        code: "Sm",
        imageSource: require("../static/components/straight-male-lock-shield.png"),
        allowedProductCodes: ["NC-Sf", "NC-Vm"],
        tooltip: "For isolation only, to be left fully open during operation."
      },
    ],
  },
  sensorAndHeads: {
    productCodeIndicies: [6, 8],
    title: "Sensors & Heads",
    step: 5,
    components: [
      {
        name: "Snap-on-Gas",
        code: "-S",
        imageSource: require("../static/components/snap-on-gas-sensor-and-heads.png"),
        tooltip: "Fastest thermostatic sensor on the market. Approximately 5% energy savings when compared to liquid sensors. Built in temperature limiting and tamper proofing. Hygienic and easy clean for healthcare premises.",
        bestPractice: true,
      },
      {
        name: "Manual – Manual Non-thermostatic",
        code: "-M",
        imageSource: require("../static/components/manuel-non-thermostatic-sensor-and-heads.png"),
        tooltip: "Required in the room with the programmable room thermostat."
      },
      {
        name: "Remote Sensor",
        code: "-R",
        imageSource: require("../static/components/remote-senor-sensor-and-heads.png"),
        tooltip: "Remote sensor (2m) from radiator for improved comfort. Required for LST radiators and partially obstructed radiators."
      },
      {
        name: "Remote Adjuster",
        code: "-A",
        imageSource: require("../static/components/remote-adjuster-senor-and-heads.png"),
        tooltip: "Remote adjuster (5m) from radiator for improved acessibility. Large, ergonomic and easy adjustable dial for assisted living and care homes. Can be used with LST radiators."
      },
      {
        name: "Liquid",
        code: "-L",
        imageSource: require("../static/components/liquid-sensor-and-heads.png"),
        tooltip: "Cost effective thermostatic sensor."
      },
      {
        name: "Liquid Chrome",
        code: "-C",
        imageSource: require("../static/components/liquid-chrome-sensor-and-heads.png"),
        tooltip: "Chrome aesthetics for designer homes Danfoss living design®."
      },
      {
        name: "Low Temp 22°C",
        code: "-F",
        imageSource: require("../static/components/low-temp-22-sensor-and-heads.png"),
        tooltip: "For precise temperature limiting and control for commercial applications. Approximately 5% energy saving for each 1°C lower room temperature."
      },
      {
        name: "Eco 2 E-TRV",
        code: "-E",
        imageSource: require("../static/components/eco-2-e-trv-sensor-and-heads.png"),
        tooltip: "Danfoss Eco™ stand-alone  smart radiator thermostat that gives easy in-home control of each individual radiator. The thermostat works with Bluetooth® control and schedule individual radiators from a smartphone device."
      },
    ],
  },
  accessories: {
    productCodeIndicies: [8, 11],
    title: "Accessories",
    step: 6,
    components: [
      {
        name: "Drain-off Tailpiece",
        code: "(D)",
        imageSource: require("../static/components/drain-off-tailpiece-accessories.png"),
        tooltip: "For radiators connection types, TBOE, TBSE and BBOE. Drain-off tailpiece is an extension of the lockshield valve.",
      },
      {
        name: "Drain Cock",
        code: "(C)",
        imageSource: require("../static/components/drain-cock-accessories.png"),
        tooltip: "For radiator connection types, TBOE and TBSE. Neater looking mounting, installed on the opposite side of the radiator to the lockshield valve.",
      },
      {
        name: "No Accessories",
        code: "",
        imageSource: require("../static/components/no-image.png"),
        tooltip: "No accessories",
      },
    ],
  },
};
