import React, { useCallback, useMemo } from "react";
import { PT40ComponentGroup, Pt40Component } from "../../data/data";
import { Button, Card, Divider, Tab, Tabs, Tooltip } from "@mui/material";
import ComponentCard from "../ComponentCard";
import { camelCaseToTitleCase } from "../../util";
import { RecoilState, useRecoilState } from "recoil";
import { SelectedPT40Components } from "../../recoil/pt40-residential-recoil-state";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { Mail } from "@mui/icons-material";
import { DEFAULT_PT40_RESIDENTIAL_PRODUCT_CODE } from "../../hooks/usePt40ResidentialProductCode";

type Props = {
  recoilTabSelector: RecoilState<number>;
  recoilStatePt40Component: RecoilState<SelectedPT40Components>;
  pt40ComponentData: PT40ComponentGroup;
  pt40ProductCodeHook: () => {
    productCode: string;
    createMailToWithProductCode: () => string;
    calculateCurrentProductCode: (
      currentStep: keyof SelectedPT40Components
    ) => string | undefined;
  };
};

export default function Pt40Selector({
  recoilTabSelector,
  recoilStatePt40Component,
  pt40ComponentData,
  pt40ProductCodeHook,
}: Props) {
  const maxStep = Object.keys(pt40ComponentData).length;
  const [selectedTabState, setSelectedTabState] =
    useRecoilState(recoilTabSelector);

  const [pt40ComponentState, setPt40ComponentState] = useRecoilState(
    recoilStatePt40Component
  );

  const currentKey = useMemo(() => {
    const PT40Keys = Object.keys(pt40ComponentData) as Array<
      keyof typeof pt40ComponentData
    >;

    const currentKey = PT40Keys[selectedTabState];
    return currentKey;
  }, [selectedTabState, pt40ComponentData]);

  const {
    productCode,
    createMailToWithProductCode,
    calculateCurrentProductCode,
  } = pt40ProductCodeHook();

  const selectedComponent = useMemo(() => {
    const component = pt40ComponentState[currentKey];
    return component;
  }, [currentKey, pt40ComponentState]);

  const onUpdateSavedComponentsState = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (selectedComponent) {
      selectedTabState < maxStep &&
        setSelectedTabState((prevState) => prevState + 1);
    }
  };

  const PT40StepperTabs = [...Object.keys(pt40ComponentData), "Summary"].map(
    (key, index) => {
      let label = '';
      if (key !== "Summary") {
        label = `${index + 1}. ${pt40ComponentData[key as keyof typeof pt40ComponentData]['title'] ?? 'Empty'}`;
      } else {
        label = `${index + 1}. Summary`;
      }
      const isStepCompleted = index < selectedTabState;
      const isCurrentStep = index === selectedTabState;

      const derterminedColor = () => {
        if (isCurrentStep) {
          return "#0032a5";
        } else if (isStepCompleted) {
          return "#0032A5";
        } else {
          return "#3D3D3E";
        }
      };
      const determinedOpcaity = () => {
        if (isCurrentStep) {
          return "1!important";
        } else if (isStepCompleted) {
          return "0.5!important";
        } else {
          return "0.5!important";
        }
      };
      return (
        <div className="p-2 test">
          <Tab
            key={key + index}
            label={label} 
            sx={{
              opacity: determinedOpcaity,
              paddingBottom: isStepCompleted ? "" : "1rem",
              borderBottom: `0.2rem solid ${derterminedColor()}`,

              color: derterminedColor(),
              fontWeight: "800",
              fontSize: "1rem",
              maxWidth: "100%",
              fontFamily: 'ItalianPlateDemiBold',
            }}
            value={index}
            disabled={true}
            className="stage-button"
          />
        </div>
      );
    }
  );

  const updateComponentsState = useCallback(
    (component: Pt40Component) => {
      setPt40ComponentState((prevState) => {
        const newState = { ...prevState };

        newState[currentKey] = component;
        return newState;
      });
    },
    [currentKey, setPt40ComponentState]
  );

  const renderedComponents = useMemo(() => {
    if (maxStep === selectedTabState) {
      return Object.entries(pt40ComponentState).map(
        ([key, component], index ) => {
          const currentProductCode = calculateCurrentProductCode(
            key as keyof typeof pt40ComponentState
          );
          // console.log(component);
          // console.log(currentProductCode);

          //here we have to get the product for the specfic step we are at and then add the component code to preview it
          return (
            <ComponentCard
              tooltip={component.tooltip}
              bestPractice={component.bestPractice}
              key={component.code + component.name}
              header={currentProductCode + component.code}
              indexKey={key}
              index={index}
              isSelected={false}
              onClick={() => {}}
              component={component}
            />
          );
        }
      );
    } else {
      const currentProductCode = calculateCurrentProductCode(currentKey);
      const currentProductCodeRemaineder = currentProductCode?.split(
        DEFAULT_PT40_RESIDENTIAL_PRODUCT_CODE
      )[1];
      const components = pt40ComponentData[currentKey].components
        .filter((component) => {
          if (
            component?.allowedProductCodes &&
            component.allowedProductCodes.length > 0
          ) {
            const allowedProductCodes = component.allowedProductCodes;
            return allowedProductCodes.some((allowedProductCode) => {
              return allowedProductCode === currentProductCodeRemaineder;
            });
          }
          return true;
        })
        .map((component, index) => {
          return (
            <ComponentCard
              tooltip={component.tooltip}
              bestPractice={component.bestPractice}
              index={index}
              header={currentProductCode + component.code}
              key={component.code + component.name}
              isSelected={component.name === selectedComponent?.name}
              onClick={() => updateComponentsState(component)}
              component={component}
            />
          );
        });
      return components;
    }
  }, [
    selectedTabState,
    pt40ComponentState,
    selectedComponent,
    currentKey,
    calculateCurrentProductCode,
    maxStep,
    pt40ComponentData,
    updateComponentsState,
  ]);

  return (
    <div className="container-selector pt-40">
      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: "#FFDC00",
          },
        }}
      >
        {PT40StepperTabs}
      </Tabs>

      {selectedTabState > maxStep ? (
        <div className="flex flex-row justify-between">
          <h2>Summary page</h2>
        </div>
      ) : (
        <div className="pt-5 flex flex-row flex-wrap products-rendered">{renderedComponents}</div>
      )}
      <Divider style={{ width: "100%", padding: "1rem", display: selectedTabState === maxStep ? "none" : "flex" }} />
      {selectedTabState !== maxStep && (
      <div className="flex flex-row flex-row-reverse gap-2 mobile-button-nav">
        <Button
          sx={{ width: "20%", backgroundColor: "#0032A5", fontFamily: "ItalianPlateDemiBold", }}
          variant="contained"
          onClick={onUpdateSavedComponentsState}
          disabled={!selectedComponent}
        >
          Next
        </Button>
        <Button
          sx={{
            width: "20%",
            fontFamily: "ItalianPlateDemiBold",
          }}
          variant="outlined"
          onClick={() => {
            if (selectedTabState > 0) setSelectedTabState((prevState) => prevState - 1);
          }}
          disabled={selectedTabState <= 0}
        >
          Go back
        </Button>
        <Button
          sx={{
            width: "20%",
            fontFamily: "ItalianPlateDemiBold",
          }}
          variant="outlined"
          onClick={() => {
            if (selectedTabState >= 2) setSelectedTabState(0);
          }}
          disabled={selectedTabState < 2}
        >
          Return to Start
        </Button>
      </div>
      )}
      <Divider style={{ width: "100%", padding: "1rem", marginBottom: "20px", display: selectedTabState === maxStep ? "flex" : "none" }} />

        {selectedTabState === maxStep && (
          <>
            <h2 className="final-product-code-title">Final Product Code:</h2>
            <p className="final-product-code-desc">Copy your product code below</p>
            <div className="flex flex-row gap-2 final-product-code-space">
              <Card
                sx={{
                  width: 300,
                  height: 100,
                  padding: "1.5rem",
                  backgroundColor: "#FFDC00",
                }}
              >
                <div className="flex flex-row justify-between flex-nowrap">
                  <b> {productCode}</b>
                  <Tooltip title="Copy code to clipboard" placement="top">
                    <CopyAllIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigator.clipboard.writeText(productCode);
                      }}
                    />
                  </Tooltip>
                </div>
              </Card>
            </div>
            <div className="flex flex-row-reverse flex-row gap-2 navigation-buttons">
            {selectedTabState === maxStep && selectedTabState > 0 && (
                      <Button
                      sx={{
                        width: "20%",
                        backgroundColor: "#0032A5",
                        fontFamily: "ItalianPlateDemiBold",
                      }}
                      variant="contained"
                      startIcon={<Mail />}
                      onClick={() => {
                        window.open(createMailToWithProductCode(), "_blank");
                      }}
                    >
                      Contact us
                    </Button>
            )}
            {selectedTabState === maxStep && selectedTabState > 0 && (
            <Button
              sx={{
                width: "20%",
                fontFamily: "ItalianPlateDemiBold",
              }}
              variant="outlined"
              onClick={() => {
                setSelectedTabState((prevState) => {
                  return prevState > 0 ? prevState - 1 : prevState;
                });
              }}
            >
              Go back
            </Button>
          )}
            {selectedTabState === maxStep && selectedTabState > 0 && (
          <Button
            sx={{
              width: "20%",
              fontFamily: "ItalianPlateDemiBold",
            }}
            variant="outlined"
            onClick={() => {
              setSelectedTabState(0);
            }}
          >
            Return to Start
          </Button>
            )}
            </div>
          </>
        )}
      </div>
  );
}
